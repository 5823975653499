<template>
  <div>
    <div class="d-flex align-center px-4 py-2 mt-2">
      <div class="w-100">
        <div class="font-weight-bold primaryColor">Dashboard
          <!-- <v-progress-circular class="ml-2" indeterminate v-if="loading" size="18" :width="2" color="black"></v-progress-circular> -->
        </div>
        <div class="pt-3 d-flex justify-space-between">
          <div>
            <v-slide-group v-model="tabSelect" mandatory>
              <v-slide-item v-for="n in tabs" :key="n" v-slot="{ active,toggle }" :value="n">
                <span @click="date = null;userId1=''">
                  <v-btn depressed height="26" class="fsize12 text-capitalize mr-2" :id="n" @click="toggle" :color="active ? 'primary' : 'grey lighten-3'">{{n}}</v-btn>
                </span>
              </v-slide-item>
            </v-slide-group>
          </div>
        </div>
      </div>
    </div>
    <v-divider class="mx-4"></v-divider>
    <v-card class="ma-4 pa-4 rounded-lg">
      <div class="d-flex justify-space-between">
        <div class="primaryColor" id="app_details_label">App Details</div>
        <v-btn height="40" depressed color="primary" class="text-capitalize fsize14" @click="$router.push({path:'/updateVendor'})" id="create_new_btn">Create New App</v-btn>
      </div>
      <v-simple-table class="my-6">
        <thead>
          <tr>
            <th v-for="(i,idex) in headers" :key="idex" :class="i.class">{{i.title}}</th>
          </tr>
        </thead>
        <tbody>
          <tr class="cursor_p table_rw" v-for="(i,idex) in vendorList" :key="idex">
            <td @click="vendorDetails(i)" :id="`${idex}_app_s_no`">{{idex+1}}</td>
            <td @click="vendorDetails(i)" :id="`${idex}_app_name`">{{i.appName}}</td>
            <td @click="vendorDetails(i)" :id="`${idex}_app_key`">{{i.apiKey}}</td>
            <td @click="vendorDetails(i)" :id="`${idex}_app_NA`">NA</td>
            <!-- <td @click="vendorDetails(i)">{{i.mobieNo}}</td>
            <td @click="vendorDetails(i)">{{i.contactName}}</td> -->
            <td @click="vendorDetails(i)" :id="`${idex}_app_redirection_url`">{{i.redirectUrl}}</td>
            <td class="text-center">
              <!-- <v-icon class="mx-2" @click="edit(i)">mdi-pencil</v-icon> -->
              <!-- <v-btn :ripple="false" height="24" color="red" text depressed outlined @click="vendorDetails(i)" class="text-capitalize fsize12">Edit</v-btn> -->
              <button class="edit_btn" @click="vendorDetails(i)" :id="`${idex}_app_edit_btn`">Edit</button>
              <!-- <v-icon class="mx-2" @click="deleteVendor(i.appId)">mdi-delete</v-icon> -->
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import commonFunc from "../mixins/commonFunctions";
export default {
  //   mixins: [commonFunc],
  name: "vendor",
  data: () => ({
    tabs: ["Applications"],
    tabSelect: "Reset",
    headers: [
      {
        id: "s_no_head",
        title: "S.No",
        class: "text-left",
      },
      {
        id: "app_name_head",
        title: "App Name",
        class: "text-left",
      },
      {
        id: "app_code_head",
        title: "App Code",
        class: "text-left",
      },
      {
        id: "expiry_head",
        title: "Expiry",
        class: "text-left",
      },
      // {
        // id: "mobile_no_head",
      //   title: "Mobile No",
      //   class: "text-left",
      // },
      // {
        // id: "contact_name_head",
      //   title: "Contact Name",
      //   class: "text-left",
      // },
      {
        id: "redirect_url_head",
        title: "Redirect Url",
        class: "text-left",
      },
      {
        id: "action_head",
        title: "Action",
        class: "text-center",
      },
    ],
    data: [
      {
        status: "Active",
        name: "Amoga",
        api_key: "sadfsdfsdfesd21334QW2",
        expiry: "17 may 2021",
      },
    ],
  }),
  computed: {
    ...mapGetters("vendor", {
      vendorList: "getVendorList",
    }),
    ...mapGetters({
      loading: "getLoader",
    }),
    // computedDateFormatted: {
    //   get() {
    //     return this.formatDate(this.date);
    //   },
    //   set() {},
    // },
  },
  watch: {},
  methods: {
    async getApiKeyDetails(type) {
      if (this.$refs.forms.validate()) {
        let formData = new FormData();
        formData.append(
          "userId",
          type == "update" ? this.apiKeyDetails.user_id : this.userId
        );
        await this.$store.dispatch("apiKey/getApiKeyDetails", {
          data: formData,
          type: type,
        });
      }
    },
    async edit(data) {
      localStorage.setItem("currentAppData", JSON.stringify(data));
      this.$router.push(`/updateVendor?type=edit&appId=${data.appId}`);
    },
    // async deleteVendor(appId) {
    //   let json = {
    //     appId: appId,
    //   };
    //   await this.$store.dispatch("vendor/deleteVendor", json);
    // },
    async vendorDetails(data) {
      localStorage.setItem("currentAppData", JSON.stringify(data));
      this.$router.push(`/vendorDetails?appId=${data.appId}`).catch(() => {});
    },
  },
  created() {
    this.$store.dispatch("vendor/getVendors");
  },
};
</script>

<style>
</style>